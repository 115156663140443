@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-LightItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-MediumItalic.ttf) format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-SemiBoldItalic.ttf) format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-BoldItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-ExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-ExtraBoldItalic.ttf) format("truetype");
    font-weight: 800;
    font-style: italic;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}
html,
body,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
body {
    /*background-image: url("/background.svg");*/
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

textarea,
select,
input,
button {
    outline: 0;
}

/* @fontface {
    font-family: "Open Sans";
    src: url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
} */
